'use client';

import React from 'react';
import { useTrackingCode } from 'react-hubspot-tracking-code-hook';
import Container from '@src/components/Shared/Container';
import RenderBodyCopy from '@src/components/Shared/RenderBodyCopy';
import RenderButtons from '@src/components/Shared/RenderButtons';
import usePreviewContent from '@src/hooks/usePreviewContent';
import constructURL from '@src/utils/constructURL';
import { PageNotFoundClientPropTypes } from './NotFoundPage.types';
const PageNotFoundClient: React.FC<PageNotFoundClientPropTypes> = ({
  data
}) => {
  // Enable live updates for the post
  const page = usePreviewContent({
    content: data?.page
  });

  // Set the page view path for analytics tracking
  const {
    setPathPageView,
    setContentType
  } = useTrackingCode();
  setPathPageView(constructURL({
    languageCode: page?.languageCode?.toLowerCase(),
    slug: `/404`,
    baseURL: ''
  }));
  setContentType('site-page');
  return <Container data-sentry-element="Container" data-sentry-component="PageNotFoundClient" data-sentry-source-file="NotFoundPage.client.tsx">
      <div className='3l:mt-[20.7rem] mb-[17.6rem] mt-[10rem] flex flex-col justify-center text-center'>
        <h1 className='header-copy mb-[3.2rem] text-[5.6rem] font-bold'>{page?.pageName}</h1>
        <p className='text-header-copy mb-[3.2rem] text-[3.8rem] font-medium'>{page?.notFound?.items?.[0]?.title}</p>

        <RenderBodyCopy className='leading-[4rem]' bodyCopy={page?.notFound?.items?.[0]?.bodyCopy?.json?.content} data-sentry-element="RenderBodyCopy" data-sentry-source-file="NotFoundPage.client.tsx" />
        <RenderButtons items={page?.notFound?.items?.[0]?.buttonCollection?.items} className='!mt-[1rem] justify-center' data-sentry-element="RenderButtons" data-sentry-source-file="NotFoundPage.client.tsx" />
      </div>
    </Container>;
};
export default PageNotFoundClient;